<template>
  <div>
    <div class="table-operator" align="left">
      <a-button type="primary" @click="handleClearStockOne" >清空库存</a-button>
      <a-button type="primary" @click="handleStock">批量设置</a-button>
    </div>
    <div class="ddcc">
      <a-calendar :value="calendar" mode="month" @select="onSelect" @panelChange="onPanelChange" >
        <div slot="dateCellRender" slot-scope="value">
          <div v-for="item in getListData(value)" style=" text-align: center;height: 50px;width: 50px;margin: auto" >
            <span :class="item.cla" >{{ item.stock }}</span>
          </div>
        </div>
      </a-calendar>
    </div>
    <a-modal v-model="visible" title="设置库存" @ok="handleOk">
      <a-form-model
        ref="ruleForm"
        :rules="rules"
        :model="forma"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item required label="日期">
          <a-date-picker :disabled-date="disabledDate" v-model="forma.stockdate" name="stockdate">
          </a-date-picker>
        </a-form-model-item>
        <a-form-model-item required label="库存">
          <a-input-number v-model="forma.stock" placeholder="">
          </a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :width="700" v-model="stockVisible" title="批量设置" @ok="handleOkStock">
      <a-form-model
        ref="ruleStockForm"
        :model="modelForm"
        :label-col="{ span: 4 }"
        :wrapper-col=" { span: 20 }"
      >
        <a-form-model-item label="选择类型" prop="type">
          <a-radio-group v-model="modelForm.type">
            <a-radio :value="1">
              库存时间段
            </a-radio>
            <a-radio :value="2">
              指定日期
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <div v-if="modelForm.type==1">
          <a-form-model-item label="日期" prop="stockdate">
            <a-range-picker
              v-model="modelForm.stockdate"
              :disabled-date="disabledDate"
              format="YYYY-MM-DD"
            />
          </a-form-model-item>
          <a-form-model-item label="库存" prop="stock">
            <a-input-number v-model="modelForm.stock" :min="0"/>
          </a-form-model-item>
        </div>
        <div v-else>
          <a-form-model-item
            v-for="(item, index) in modelForm.stocklist"
            :key="index"
          >

            <a-date-picker :disabled-date="disabledDate" v-model="modelForm.stocklist[index].datevalue"/>
            <a-input-number
              placeholder="请输入库存"
              v-model="modelForm.stocklist[index].stockvalue"
              style="margin-left: 10px;width: 140px"
              :min="0"
            />
            <a-icon
              v-if="modelForm.stocklist.length>1"
              :disabled="modelForm.stocklist.length === 1"
              class="dynamic-delete-button"
              type="minus-circle-o"
              style="margin-left: 10px;"
              @click="removeDomain(index)"
            />
            <a-button v-if="index==0" type="primary" style=" margin-left: 8px" @click="addDomain()">
              <a-icon type="plus"/>
              增加
            </a-button>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { calendar as ACalendar } from 'ant-design-vue'
import { clearstockone } from '@/api/nosodochium'
import { stockFetchList, stockSave, BatchStockSave } from '@/api/nosodochiumstock'
import moment from 'moment'

export default {
  components: {
    ACalendar
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      calendar: undefined,
      noseId: 0,
      visible: false,
      stockVisible: false,
      forma: {
        stock: 0,
        stockdate: undefined
      },
      rules: {
        stockdate: [{ required: true }],
        stock: [{ required: true }]
      },
      datalist: [],
      modelForm: {
        type: 1,
        stock: 0,
        stocklist: []
      }
    }
  },
  methods: {
    disabledDate (current) {
      const weekDay = current.format('d')
      return current && current <= moment().add(0, 'days')
    },
    getListData (value) {
      const listData = []
      const today = moment().format('YYYY-MM-DD')
      const val = moment(value).format('YYYY-MM-DD')
      let i = 0
      const datalistpath = this.datalist
      for (; i < datalistpath.length; i++) {
        if (val == datalistpath[i].stockdate) {
          if (datalistpath[i].stockdate >= today) {
            if (datalistpath[i].stock > 0) {
              datalistpath[i].cla = 'green'
              datalistpath[i].type = 3
            } else {
              datalistpath[i].cla = 'red'
              datalistpath[i].type = 2
            }
          } else {
            datalistpath[i].cla = 'huise'
            datalistpath[i].type = 1
          }
          listData[0] = datalistpath[i]
        }
      }
      return listData
    },
    setNoseId (val) {
      this.noseId = val
      console.log(val)
      this.fetctList(moment().format())
    },
    handleClearStockOne () {
      const self = this
      this.$confirm({
        title: '确定要清除库存吗？',
        content: '清除库存后将不可恢复，请谨慎操作！',
        okText: '确认',
        cancelText: '取消',
        onOk () {
          clearstockone({ 'nosoId': self.noseId })
            .then(res => {
              if (res.code === 1) {
                self.fetctList(moment().format())
                self.$message.info(res.message)
              } else {
                self.$message.error(res.message)
              }
            })
        }
      })
    },
    onPanelChange (value) {
      this.calendar = value
      this.fetctList(value.format())
    },
    handleOk (e) {
      //
      const self = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.forma.stockdate = moment(this.forma.stockdate).format('YYYY-MM-DD')
          if (this.forma.noseId > 0) {
            stockSave(this.forma)
              .then(res => {
                if (res.code === 1) {
                  this.visible = false
                  this.fetctList(moment().format())
                  self.$message.info(res.message)
                } else {
                  self.$message.error(res.message)
                }
              })
          } else {
            self.$message.info('请选择医院')
          }
        } else {
          return false
        }
      })
    },
    fetctList (stockMonth) {
      const self = this
      const startdate = moment(stockMonth).startOf('month').format('YYYY-MM-DD')
      const enddate = moment(stockMonth).endOf('month').format('YYYY-MM-DD')
      stockFetchList({ 'nosoId': this.noseId, startdate: startdate, enddate: enddate })
        .then(res => {
          const s = res.result.data
          const arr = []
          for (let i = 0; i < s.length; i++) {
            arr.push({ 'stockdate': moment(s[i].stockdate).format('YYYY-MM-DD'), 'stock': s[i].stock, 'stockId': s[i].stockId })
          }
          self.datalist = arr
        })
    },
    onSelect (value) {
      const today = moment().format('YYYY-MM-DD')
      const val = moment(value).format('YYYY-MM-DD')
      const datalistpath = this.datalist
      this.forma.stockdate = value
      this.forma.stock = 0
      this.forma.stockId = 0
      this.forma.noseId = this.noseId
      if (val >= today) {
        this.visible = true
        for (let i = 0; i < datalistpath.length; i++) {
          if (val === datalistpath[i].stockdate) {
            this.forma.stock = datalistpath[i].stock
            this.forma.stockId = datalistpath[i].stockId
          }
        }
      }
    },
    handleStock () {
      this.stockVisible = true
      this.modelForm.noseId = this.noseId
      this.modelForm.stocklist = []
      if (this.modelForm.stocklist.length < 1) {
        this.addDomain()
      }
    },
    handleOkStock () {
      const self = this
      let param = {}
      if (this.modelForm.type === 1) {
        if (this.modelForm.stock < 0) {
          this.$message.info('请输入库存')
          return false
        }
        if (this.modelForm.stockdate.length !== 2) {
          this.$message.info('请选择日期')
          return false
        }
        param = {
          noseId: this.modelForm.noseId,
          stock: this.modelForm.stock,
          startdate: moment(this.modelForm.stockdate[0]).format('YYYY-MM-DD'),
          enddate: moment(this.modelForm.stockdate[1]).format('YYYY-MM-DD'),
          type: this.modelForm.type
        }
      } else {
        param = {
          noseId: this.modelForm.noseId,
          type: this.modelForm.type
        }

        const arr = []
        const item = this.modelForm.stocklist
        for (let i = 0; i < item.length; i++) {
          if (item[i].stockvalue >= 0) {
            arr.push({ stockdate: moment(item[i].datevalue).format('YYYY-MM-DD'), stockvalue: item[i].stockvalue })
          }
        }
        param.stockList = JSON.stringify(arr)
      }

      BatchStockSave(param)
        .then(res => {
          if (res.code === 1) {
            this.stockVisible = false
            this.fetctList(moment().format())
            self.$message.info(res.message)
          } else {
            self.$message.error(res.message)
          }
        })
    },
    addDomain () {
      this.modelForm.stocklist.push({ datevalue: undefined, stockvalue: 0 })
    },
    removeDomain (index) {
      if (index !== -1) {
        this.modelForm.stocklist.splice(index, 1)
      }
    },
    onDatePanelChange (value) {
      console.log('onDatePanelChange')
    }
  }
}
</script>
<style>
.green{
  background-color: #52c41a;
  padding: 1px;
  font-size: 18px;
  width: 80pxpx;
  height: 80px;
  border-radius: 15px;
  display: inline-block;
  color: #f5f5f5;

}
.red{
  background-color: orangered;
  padding: 1px;
  font-size: 18px;
  display: inline-block;
  /*width: 80px;*/
  height: 80px;
  border-radius: 15px;
  color: #f5f5f5;
}
.huise{
  background-color: #848181;
  padding: 1px;
  font-size: 18px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #f5f5f5;
}
</style>
